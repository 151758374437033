import axios from "axios";
import store from "@/store";

export default {
  methods: {
    async getLocationCustomer(customerId, locationId) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/customer/${customerId}/location/${locationId}`,
        {
          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    },

    async getLocationCustomers(
      locationId,
      companyId = 0,
      meetingtypeId = -1,
      page = 1,
      itemsPerPage = 25
    ) {
      const response = await axios.get(
        `${process.env.VUE_APP_API_BASE_URL}/api/v1/customer/location/${locationId}`,
        {
          params: {
            companyId: companyId,
            meetingtypeId: meetingtypeId,
            page: page,
            itemsPerPage: itemsPerPage
          },

          headers: {
            "Content-type": "application/json",
            apiToken: process.env.VUE_APP_API_KEY,
            profileToken: store.getters['profileStore/getProfileToken']
          }
        }
      );
      return response;
    }
  }
};
