<template>
  <div v-if="customer">
    <p class="image is-128x128">
      <img
        src="@/assets/images/spacer1_1.gif"
        class="is-round has-shadow"
        :style="{backgroundImage: `url(${$options.filters.getProfileImage(customer.ProfileImage, customerId, 128)})`}"
      >
    </p>

    <p class="title">{{ customer.FullName }}</p>
  </div>
</template>

<script>
import customerProvider from '@/providers/customer'

export default {
  props: {
    customerId: {
      default: 0,
      type: Number
    },

    locationId: {
      default: 0,
      type: Number
    }
  },

  data() {
    return {
      customer: null
    }
  },

  created() {
    this.getCustomerData()
  },

  beforeDestroy() {
    this.customer = null
  },

  methods: {
    getCustomerData() {
      customerProvider.methods.getLocationCustomer(this.customerId, this.locationId).then(response => {
        if (response.status === 200) {
          this.customer = response.data
        }
      })
    }
  }
}
</script>
